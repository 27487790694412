import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from "../contexts/appContexts";
import NativeModal from "../components/nativeModal";

const Splash = ({ delay = 2000 }) => {
  const { nextStep } = useContext(GlobalContext);

  const [ openNativeModal, setOpenNativeModal ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenNativeModal(true);
    }, delay);
  }, []);

  const allowTrack = () => {
    nextStep();
  };

  const notAllowTrack = () => {
    nextStep();
  };

  const nativeModalButtons =
    (<>
      <button className="button-native" onClick={ () => { allowTrack(); } }>
        Allow Tracking
      </button>
      <button className="button-native" onClick={ () => { notAllowTrack(); } }>
        Ask App Not to Track
      </button>
    </>);

  return (
    <div className="splash-page">
      <svg className="splash-page_icon">
        <use xlinkHref="#svg_logo" />
      </svg>
      {openNativeModal && <NativeModal buttons={ nativeModalButtons }>
        <>
          <h2>
            “PayPal” would like permission to track you across apps and websites owned by other companies.
          </h2>
          <p>
            Your data will be used to customize the offers you see.
          </p>
        </>
      </NativeModal>}
    </div>
  );
};

export default Splash;


