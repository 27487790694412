import React, { useContext, useState, useRef, useEffect } from 'react';
import { map, keys } from "lodash";
import { InputMask } from '@react-input/mask';
import Modal from "../components/modal";
import FormControl from "../components/formControl";
import { GlobalContext } from '../contexts/appContexts';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import { phoneMask, phoneMin } from '../helpers/phoneHelper';

const MobileNumber = ({ headerStep, numberOfHeaderSteps }) => {
  const { nextStep, prevStep, currentData, mergeData } = useContext(GlobalContext);
  const [ initialPhone, setInitialPhone ] = useState(currentData.phone || '');
  const [ openModal, setOpenModal ] = useState(false);

  const handleNextStep = () => {
    mergeData({ phone: initialPhone });
    nextStep();
  };

  const numberOfDigits = 6;
  const [ randomNumbers, setRandomNumbers ] = useState(null);

  const confirmationInterval = useRef(null);
  const confirmationRedirectionInterval = useRef(null);
  const phoneInputRef = useRef(null);

  const handleConfirmationChange = () => {
    confirmationInterval.current = setTimeout(() => {
      setRandomNumbers(
        map(
          Array(numberOfDigits),
          (item, index) => {
            const max = index ? 9 : 8;
            const plus = index ? 0 : 1;
            return Math.floor(Math.random() * max) + plus;
          }
        )
      );
      confirmationInterval.current = null;
    }, 100);
  };

  useEffect(() => {
    if (openModal) {
      // handleConfirmationChange();
    } else {
      confirmationInterval.current && clearTimeout(confirmationInterval.current);
      confirmationRedirectionInterval.current && clearTimeout(
        confirmationRedirectionInterval.current
      );
      setRandomNumbers(null);
    }
  }, [ openModal ]);

  useEffect(() => {
    if (randomNumbers) {
      confirmationRedirectionInterval.current = setTimeout(() => {
        handleNextStep();
      }, 500);
    } else {
      confirmationInterval.current && clearTimeout(confirmationInterval.current);
      confirmationRedirectionInterval.current && clearTimeout(
        confirmationRedirectionInterval.current
      );
    }
  }, [ randomNumbers ]);

  const handleCloseModal = () => {
    mergeData({ phoneClosedConfirmationPopup: true });
    setOpenModal(false);
  };

  const disabledPhoneNextButton = (initialPhone.length !== phoneMask.length);

  const handlePhoneNext = () => {
    if (!!currentData.phone && currentData.phone === initialPhone) {
      handleNextStep();
    } else {
      setOpenModal(true);
    }
  };

  const footerButtons = (
    <button
      className="button -full-width"
      onClick={ handlePhoneNext }
      disabled={ disabledPhoneNextButton }
    >
      Next
    </button>
  );

  const footer = <Footer footerButtons={ footerButtons } />;

  const handleBack = () => {
    mergeData({ phoneBackClick: true });
    prevStep();
  };

  const header = (
    <Header
      onBack={ handleBack }
      currentStep={ headerStep }
      numberOfSteps={ numberOfHeaderSteps }
    />
  );

  const handleResendClick = () => {
    mergeData({ phoneResendClick: true });
  };

  const handleOnChange = (newValue) => {
    setInitialPhone(newValue);
  };

  return (
    <Layout header={ header } footer={ footer }>
      <div className="mobile-number">
        <div className="title mobile-number_title">
          <h1 className="title_h1">What’s your number?</h1>
        </div>
        <div className="mobile-number_form form">
          <InputMask
            component={ FormControl }
            initialText={ initialPhone }
            label="Mobile number"
            type="tel"
            onChange={ handleOnChange }
            inputRef={ phoneInputRef }
            mask={ phoneMask }
            replacement={ { _: /\d/ } }

          />
          <div className="form_hint">
            By continuing, you confirm that you are authorized to use this
            { ' ' }
            phone number and agree to receive text messages. Carrier fees may apply.
          </div>
        </div>
      </div>
      {
        openModal &&
          <Modal handleClose={ handleCloseModal }>
            <div className="confirm-phone">
              <div className="title confirm-phone_title">
                <h1 className="title_h1">Confirm your phone</h1>
                <div className="title_hint">
                  <p className="title_hint-line">
                    We sent a code to { phoneMin(initialPhone) }.
                  </p>
                  <p className="title_hint-line">
                    <button className="button-link" onClick={ handleResendClick }>
                      Resend the code
                    </button>
                  </p>
                </div>
              </div>
              <div className="confirm-phone_form form">
                <div className="confirm-phone_form-grid">
                  {
                    map([ ...keys(Array(numberOfDigits)) ], (item, index) => (
                      <FormControl
                        key={ index }
                        disableChange
                        onClick={ handleConfirmationChange }
                        initialText={ randomNumbers ? randomNumbers[index] : '' }
                        type="number"
                        oneSign
                      />
                    ))
                  }
                </div>
              </div>
            </div>
          </Modal>
      }
    </Layout>
  );
};

export default MobileNumber;


