import React from "react";

const BonusTermsApply = () => {
  return (
    <div className="agreement">
      <div className="agreement_content">
        <p>
          Bonus Terms
        </p>
      </div>
    </div>
  );
};

export default BonusTermsApply;
