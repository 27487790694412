import React, { useContext, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { map } from "lodash";
import cn from "classnames";
import renderRawHtml from '../../../../common/render_raw_html';
import slideImage0 from '../../../../../../images/paypal/everywhere/welcome/0.jpg';
import slideImage1 from '../../../../../../images/paypal/everywhere/welcome/1.jpg';
import slideImage2 from '../../../../../../images/paypal/everywhere/welcome/2.jpg';
import slideImage3 from '../../../../../../images/paypal/everywhere/welcome/3.jpg';
import slideImage4 from '../../../../../../images/paypal/everywhere/welcome/4.jpg';
import slideImage5 from '../../../../../../images/paypal/everywhere/welcome/5.jpg';
import { GlobalContext } from "../contexts/appContexts";

const slideImages = [
  slideImage0, slideImage1, slideImage2, slideImage3, slideImage4, slideImage5
];

const slidesList = [
  {
    id: 1,
    title: "Pay,<br />send,<br />and<br />save<br />smarter.",
    fontLg: true,
    position: "bottom"
  },
  {
    id: 2,
    title: "Shop online and now in stores, too.",
    showButton: true,
    position: "bottom"
  },
  {
    id: 3,
    title: "Get cash on cash back! It all adds up.",
    showButton: true,
    position: "top"
  },
  {
    id: 4,
    title: "Send money  instantly to just about anyone anywhere.",
    showButton: true,
    position: "bottom"
  },
  {
    id: 5,
    title: "Pay with confidence. Safety and privacy are built in.",
    showButton: true,
    position: "top"
  }
];

const Intro = ({ onCloseApp }) => {
  return (
    <div className="welcome-intro">
      {
        !!onCloseApp &&
        <div className="slideshow_header -reverse-grid">
          <button className="button-icon header_back close-app" onClick={ onCloseApp }>
            <svg className="icon header_back-icon">
              <use xlinkHref="#svg_close" />
            </svg>
          </button>
        </div>
      }
      <img className="welcome-intro_bg" src={ slideImages[0] } alt="" />
      <svg className="welcome-intro_icon">
        <use xlinkHref="#svg_paypal" />
      </svg>
    </div>
  );
};

const Slideshow = ({ nextStep, onSkip, slideTime, onCloseApp }) => {
  const delayAfter = 1000;
  const [ activeSlide, setActiveSlide ] = useState(1);
  const intervalId = useRef(null);
  const timeoutId = useRef(null);

  useEffect(() => {
    let currentSlide = activeSlide;
    intervalId.current = setInterval(() => {
      if (currentSlide < slidesList.length) {
        currentSlide = currentSlide + 1;
        setActiveSlide(currentSlide);
      }
    }, slideTime);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [ activeSlide ]);

  useLayoutEffect(() => {
    if (activeSlide >= slidesList.length) {
      timeoutId.current = setTimeout(() => {
        setActiveSlide(1);
      }, delayAfter + slideTime);
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [ activeSlide ]);

  const skipStep = () => {
    onSkip && onSkip();
    let newSlide = activeSlide + 1;
    if (activeSlide >= slidesList.length) {
      newSlide = 1;
    }
    setActiveSlide(newSlide);
  };

  const onStart = () => {
    nextStep();
  };

  return (
    <div className="slideshow">
      <div className="slideshow_header -reverse-grid">
        <ul className="slideshow_header-steps">
          {
            map(slidesList, (slide, index) => (
              <li
                key={ index }
                style={ { 'animationDuration': `${slideTime}ms` } }
                className={
                  cn(
                    "slideshow_header-step",
                    { '-current': index + 1 === activeSlide },
                    { '-completed': index + 1 < activeSlide }
                  )
                }
              />
            ))
          }
        </ul>
        <button
          className="slideshow_header-button button -white -xs skip-button"
          onClick={ skipStep }
        >
          Skip
        </button>
        {
          !!onCloseApp &&
          <button className="button-icon header_back close-app" onClick={ onCloseApp }>
            <svg className="icon header_back-icon">
              <use xlinkHref="#svg_close" />
            </svg>
          </button>
        }
      </div>
      <ul className="slideshow_list">
        {
          map(slidesList, (slide, index) => (
            <li key={ index } className={ `slideshow_list-item ${index + 1 === activeSlide ? '-active' : ''}` }>
              <div className="slideshow_item">
                <img className="slideshow_item-bg" src={ slideImages[index + 1] } alt="" />
                <div className={ `slideshow_item-content -position-${slide.position || 'bottom'}` }>
                  <h1
                    className={ `slideshow_item-title ${slide.fontLg ? '-lg' : ''}` }
                    { ...renderRawHtml(slide.title) }
                  />
                  {
                    slide.showButton &&
                    <div className="slideshow_item-button">
                      <button className="button -full-width -black" onClick={ onStart }>
                        Get Started
                      </button>
                    </div>
                  }
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

const Welcome = ({ delayFirst = 4000, slideTime = 4000 }) => {
  const { nextStep, mergeData, closeFullApp } = useContext(GlobalContext);
  const [ startSlideshow, setStartSlideshow ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStartSlideshow(true);
    }, delayFirst);
  }, []);

  const handleOnSkip = () => {
    mergeData({ welcomeSkipClick: true });
  };

  return (
    !startSlideshow ? (
      <Intro onCloseApp={ closeFullApp } />
    ) : (
      <Slideshow
        onSkip={ handleOnSkip }
        nextStep={ nextStep }
        slideTime={ slideTime }
        onCloseApp={ closeFullApp }
      />
    )
  );
};

export default Welcome;


