import React, { useState } from "react";
import classnames from "classnames";
import Modal from "./modal";
import FormControlSelect from "./formControlSelect";

const FormControlAddress = ({ value, setValue, label, addressList }) => {
  const [ modalSearchAddress, setModalSearchAddress ] = useState(false);
  const handleSelect = (item) => {
    setValue(item);
    setModalSearchAddress(false);
  };

  const openModalSearchAddress = () => {
    setModalSearchAddress(true);
  };
  const closeModalSearchAddress = () => {
    setModalSearchAddress(false);
  };

  return (
    <>
      <div className={ `form-control` }>
        <div
          className={
            classnames(
              "form-control_field -with-icon",
              { '-with-label': !!label }
            )
          }
        >
          { !!value && (
            <div className="form-control_lines">
              { value.primary }, <br /> { value.secondary }
            </div>
          ) }

        </div>
        {!!label && <label className={ `form-control_label ${value !== null ? '-with-value' : ''}` }>{ label }</label>}

        <button className="button-icon form-control_button -full-width" onClick={ openModalSearchAddress }>
          <svg className="icon form-control_icon -search">
            <use xlinkHref="#svg_search" />
          </svg>
        </button>

      </div>
      {modalSearchAddress && (
        <Modal classNameDialog="-height-lg" handleClose={ closeModalSearchAddress } headerTitle={ label }>
          <FormControlSelect listOptions={ addressList } selectOption={ handleSelect } />
        </Modal>
      )}
    </>
  );
};

export default FormControlAddress;
