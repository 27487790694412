import React, { useContext, useState } from 'react';
import { map } from "lodash";
import Modal from "../components/modal";
import Notification from "../components/notification";
import TermsApply from "../modals/termsApply";
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import { GlobalContext } from '../contexts/appContexts';

const IntroSetup = ({ headerStep, numberOfHeaderSteps }) => {
  const { nextStep, skipStep, mergeData, fakeData } = useContext(GlobalContext);
  const [ openModalTerms, setOpenModalTerms ] = useState(false);
  const { introSetupList } = fakeData;

  const closeModalTerms = () => {
    setOpenModalTerms(false);
  };

  const onNext = () => {
    mergeData({ introSkip: false });
    nextStep();
  };

  const onSkip = () => {
    mergeData({ introSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      <button className="button -full-width" onClick={ onNext }>
        Set Up Your PayPal Debit Card
      </button>
      <button className="button -link -full-width" onClick={ onSkip }>
        Set this up later
      </button>
    </>
  );

  const handleOpenTerms = () => {
    mergeData({ introOpenTerms: true });
    setOpenModalTerms(true);
  };

  const footerHintAbove = (
    <>
      5% cash back earned as points you redeem for cash and other options on up to
      { " " }
      $1000 spend/month everywhere Mastercard is accepted.
      { " " }
      <button className="button-link -underlined" onClick={ handleOpenTerms }>Terms apply</button>.
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } footerHintAbove={ footerHintAbove } />;

  return (
    <Layout header={ header } footer={ footer }>
      <Notification status="success" delay={ 3000 } icon="checked-circle">
        Your PayPal account is ready.
      </Notification>

      <div className="intro-setup">
        <div className="title intro-setup_title">
          <h1 className="title_h1">Get set up to earn cash back and use PayPal in stores</h1>
        </div>

        <ul className="intro-setup_list">
          {
            map(introSetupList, (item, index) => (
              <li key={ index } className="intro-setup_list-item">
                <div className="intro-setup_item">
                  <div className="intro-setup_item-icon">
                    <svg className={ `intro-setup_item-svg -${item.id}` }>
                      <use xlinkHref={ `#svg_intro-${item.id}` } />
                    </svg>
                  </div>
                  <div className="intro-setup_item-content">
                    <h4 className="intro-setup_item-title">
                      {item.title}
                    </h4>
                    <div className="intro-setup_item-text">
                      {item.text}
                    </div>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div>
      {
        openModalTerms &&
        <Modal
          handleClose={ closeModalTerms }
          headerTitle="Terms apply"
          classNameDialog="-full-height"
          classNameContent="-bg-gray"
        >
          <TermsApply />
        </Modal>
      }
    </Layout>
  );
};

export default IntroSetup;


