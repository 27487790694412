import React from "react";

const SSNInfo = () => {
  return(
    <div className="agreement">
      <div className="agreement_content">
        <p>
          This user agreement is a contract between you and PayPal, Inc. governing your use of your PayPal account and the PayPal services. It applies to U.S. PayPal accounts only. If you are an individual, you must be a resident of the United States or one of its territories and at least 18 years old, or the age of majority in your state of residence to open a U.S. PayPal account and use the PayPal services. If you are a business, the business must be organized in, operating in, or a resident of, the United States or one of its territories to open a U.S. PayPal account and use the PayPal services.
        </p>
        <p>
          By opening and using a PayPal account, you agree to comply with all of the terms and conditions of this user agreement, including the Fee pages, and any upcoming changes described on the Policy Updates page at the time you accept this user agreement (which changes will apply to you on the indicated effective dates). These terms include an agreement to resolve disputes by arbitration on an individual basis. You also agree to comply with each of the other terms or agreements on the Legal Agreements page that apply to you and the following additional policies:
        </p>
        <p>
          We may revise this agreement and any of the other terms, agreements, or policies from time to time. The revised version will be effective at the time we post it, unless otherwise noted. If our changes reduce your rights or increase your responsibilities, we will provide notice of such changes.
        </p>
        <ul>
          <li>
            For personal accounts, notice will include posting information about the changes on our Policy Updates page. If you accepted this user agreement prior to us posting notice of the changes on our Policy Updates page, then such notice will be provided at least 21 days before the applicable effective date.
          </li>
          <li>
            For business accounts, notice will include posting information about the changes on our Policy Updates page or providing information about the changes by other written means (which may include email). If you accepted this user agreement prior to us providing notice of the changes on the Policy Updates page or by other written means, then such notice will be provided at least 5 days before the applicable effective date.
          </li>
        </ul>
        <p>
          By continuing to use our services after any changes to this user agreement or any of the other applicable terms, agreements, or policies, you agree to abide and be bound by those changes. If you do not agree with any changes to this user agreement, you may close your account.
        </p>
      </div>
    </div>
  );
}

export default SSNInfo;