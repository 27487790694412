import React, { useState, useEffect, forwardRef } from "react";
import classnames from "classnames";
import { setKeyboardClass, removeKeyboardClass } from '../helpers/iphoneKeyboardHelper';

const FormControl = ({
  initialText = '', label, oneSign, type = "text",
  onClick, onFocus, onBlur, onChange, disableChange, iconRight, iconLeft
}, forwardedRef) => {
  const [ value, setValue ] = useState(initialText);
  const [ dynamicType, setDynamicType ] = useState(type);
  useEffect(() => {
    setValue(initialText);
  }, [ initialText ]);
  const handleChange = (event) => {
    if (disableChange) {
      return;
    }
    const newValue = event.target.value;
    onChange && onChange(newValue);
    setValue(newValue);
  };
  const togglePasswordType = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (dynamicType === "password") {
      setDynamicType("text");
    } else {
      setDynamicType("password");
    }
  };

  const handleClick = (event) => {
    onClick && onClick(event);
  };

  const handleOnFocus = (event) => {
    onFocus && onFocus(event);

    setTimeout(() => {
      setKeyboardClass();
    }, 100);
  };

  const handleOnBlur = (event) => {
    onBlur && onBlur(event);

    setTimeout(() => {
      removeKeyboardClass();
    }, 100);
  };

  return (
    <>
      <div className={ `form-control` }>
        {/*todo respondent/common/components/inputNumber.js ???*/}
        {/*todo one digit check (maxLength, size)*/}
        {/*todo one number phone check*/}
        <input
          name="test"
          className={
            classnames(
              "form-control_field",
              { '-one-sign': !!oneSign },
              { '-with-label': !!label },
              { '-with-icon': (!!iconRight || type === "password" || type === "date")  },
              { '-with-icon-left': (!!iconLeft) }
            )
          }
          type={ dynamicType }
          value={ value }
          onChange={ handleChange }
          onClick={ handleClick }
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
          autoComplete="off"
          ref={ forwardedRef }
        />
        {!!label && <label className={ `form-control_label ${value.length > 0 || type === "date" ? '-with-value' : ''}` }>{ label }</label>}
        {type === "password" && (
          <button className="button-icon form-control_button" onClick={ togglePasswordType }>
            <svg className={ `icon form-control_icon -eye ${dynamicType === 'text' ? '-active' : ''}` }>
              <use xlinkHref="#svg_eye" />
            </svg>
          </button>
        )}
        {!!iconLeft && (
          <span className="form-control_icon -left">{ iconLeft }</span>
        )}
        {!!iconRight && (
          <span className="form-control_icon -right">{ iconRight }</span>
        )}
      </div>
    </>
  );
};

export default forwardRef(FormControl);
