import React, { useContext, useState } from 'react';
import FormControl from "../components/formControl";
import FormControlAddress from "../components/formControlAddress";
import FormControlReadOnly from "../components/formControlReadOnly";
import Modal from "../components/modal";
import SSNInfo from "../modals/sSNInfo";
import ReviewTerms from "../modals/reviewTerms";
import { GlobalContext } from '../contexts/appContexts';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";

const IdCheck = ({ forceBack, headerStep, numberOfHeaderSteps }) => {
  const {
    fakeData, nextStep, skipStep, prevStep, currentData, mergeData
  } = useContext(GlobalContext);
  const [ address, setAddress ] = useState(currentData.address);
  const [ date, setDate ] = useState('');
  const [ ssn, setSsn ] = useState('');
  const [ openModalSSN, setOpenModalSSN ] = useState(false);

  const [ openModalSkip, setOpenModalSkip ] = useState(false);
  const [ openModalReviewTerms, setOpenModalReviewTerms ] = useState(false);

  const handleOpenModalSSN = () => {
    mergeData({ idCheckShowTerms: true });
    setOpenModalSSN(true);
  };

  const closeModalSSN = () => {
    setOpenModalSSN(false);
  };
  const closeModalSkip = () => {
    setOpenModalSkip(false);
  };
  const closeModalReviewTerms = () => {
    setOpenModalReviewTerms(false);
  };

  const handleSsnClick = () => {
    setSsn(fakeData.ssn);
  };

  const handleDateChange = (value) => {
    setDate(value);
  };


  const onNext = () => {
    mergeData({ billingAddress: address, ssn, date, idCheckSkip: false });
    nextStep();
  };

  const onSkip = () => {
    mergeData({ billingAddress: null, ssn: null, date: null, idCheckSkip: true });
    skipStep();
  };

  const footerModalSkip = (
    <>
      <button className="button -full-width" onClick={ closeModalSkip }>
        I Want the Benefits
      </button>
      <button className="button -link -full-width" onClick={ onSkip }>
        Skip for now
      </button>
    </>
  );

  const footerModalReviewTerms = (
    <>
      <div className="modal_footer-hint -above">
        By clicking “Agree”, you confirm that you have read and agree to the disclosures and the
        { " " }
        <span className="pseudo-link">PayPal Balance Account Terms and Conditions</span>
        , the
        { " " }
        <span className="pseudo-link">PayPal Debit Mastercard® Cardholder Agreement</span>
        { " " }
        and
        { " " }
        <span className="pseudo-link">Bancorp Privacy Notice</span>.
      </div>
      <div className="modal_footer-button">
        <button className="button -full-width" onClick={ onNext }>
          Agree and Continue
        </button>
      </div>
    </>
  );

  const handleOpenSkipModal = () => {
    mergeData({ idCheckOpenSkipModal: true });
    setOpenModalSkip(true);
  };

  const disabledNext = (!date || !address || !ssn);
  const footerButtons = (
    <>
      <button
        disabled={ disabledNext }
        className="button -full-width"
        onClick={ () => { setOpenModalReviewTerms(true); } }
      >
        Next
      </button>
      <button className="button -link -full-width" onClick={ handleOpenSkipModal }>
        Set this up later
      </button>
    </>
  );

  const footerHintAbove = (
    <>
      You’re providing this information to us as part of your request for a PayPal Balance
      { " " }
      account, and to The Bancorp Bank, N.A. as part of your request for a PayPal Debit Card.
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } footerHintAbove={ footerHintAbove } />;

  const handleBack = () => {
    mergeData({ idCheckBackClick: true });
    prevStep();
  };

  const header = (
    <Header
      onBack={ forceBack ? handleBack : null }
      currentStep={ headerStep }
      numberOfSteps={ numberOfHeaderSteps }
    />
  );

  return (
    <Layout header={ header } footer={ footer }>
      <div className="id-check">
        <div className="content_title">
          <h1>Just a quick ID check</h1>
        </div>
        <div className="content_text">
          We need this to set up your PayPal Debit Card and PayPal Balance account so you can pay in stores and earn cash back.
        </div>
        <div className="id-check_form form">
          <div className="form_group">
            <FormControlReadOnly label="Legal name" value={ `${currentData.firstName} ${currentData.lastName}` } />
            <FormControlAddress
              value={ address }
              setValue={ setAddress }
              label="Address"
              addressList={ fakeData.addressList }
            />
            <FormControl
              initialText={ date }
              label="Date of birth"
              type="date"
              onChange={ handleDateChange }
            />
            <FormControl
              disableChange
              initialText={ ssn }
              label="9-digit SSN"
              type="password"
              onClick={ handleSsnClick }
            />
          </div>
          <div className="form_hint">
            Your SSN is only used to verify your identity. This won’t impact your credit
            score.
            { " " }
            <button className="button-link -underlined" onClick={ handleOpenModalSSN }>
              Why do we need your info?
            </button>
          </div>
        </div>
      </div>
      {
        openModalSSN &&
        <Modal
          handleClose={ closeModalSSN }
          headerTitle="Why do we need your info?"
          classNameDialog="-full-height"
          classNameContent="-bg-gray"
        >
          <SSNInfo />
        </Modal>
      }

      {openModalSkip && <Modal handleClose={ closeModalSkip } footer={ footerModalSkip }>
        <div className="sure">
          <div className="title modal_content-title">
            <h1 className="title_h1">Are you sure?</h1>
          </div>
          <div className="modal_content-text">
            It’s fast and free to set up. And with these additional benefits, you’ll be able to pay in stores and earn cash back.
          </div>
        </div>
      </Modal>}

      {openModalReviewTerms &&
        <Modal
          handleClose={ closeModalReviewTerms }
          footerInContent={ footerModalReviewTerms }
          scrollBottomButton
          scrollBottomButtonText="Scroll to Review"
        >
          <ReviewTerms />
        </Modal>}

    </Layout>
  );
};

export default IdCheck;


