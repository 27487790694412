import React from 'react';
import { map } from "lodash";
import imgOffer from '../../../../../../images/paypal/everywhere/home/offer.jpg';

const HomePage = ({ balance }) => {
  const calendarList = [
    { title: "Jan" },
    { title: "Feb" },
    { title: "Mar" },
    { title: "Apr" },
    { title: "May", active: true }
  ];

  return (
    <div className="home">
      <header className="home_header">
        <svg className="icon">
          <use xlinkHref="#svg_menu" />
        </svg>
        <div className="home_spacer" />
        <svg className="icon">
          <use xlinkHref="#svg_qr" />
        </svg>
        <svg className="icon -profile">
          <use xlinkHref="#svg_profile" />
        </svg>
      </header>

      <main className="home_content">

        <div className="home_plate-white">
          <div className="home_grid">
            <div className="home_balance">
              <div className="home_balance-name">PayPal balance</div>
              <div className="home_balance-val">${balance}.00</div>
            </div>
            <div className="home_cashback">
              <svg className="icon">
                <use xlinkHref="#svg_clothing" />
              </svg>
              <span>5% cash back</span>
            </div>
          </div>
          <div className="home_calendar">
            {
              map((calendarList), (item, index) => (
                <div key={ index } className="home_calendar-item">
                  <div className={ `home_calendar-item-title ${item.active ? '-active' : ''}` }>
                    {item.title}
                  </div>
                  <div className="home_calendar-item-dot -def" />
                </div>
              ))
            }
          </div>
          <div className="home_grid -center">
            <div className="home_calendar-legend">
              <div className="home_calendar-legend-circle -def" />
              <div className="home_calendar-legend-text">
                <div>May spending</div>
                <div className="bigger">$ 0.00</div>
              </div>
            </div>
            <div className="home_calendar-legend">
              <div className="home_calendar-legend-circle -blue" />
              <div className="home_calendar-legend-text">
                <div>Rewards earned</div>
                <div className="bigger">5,000 pts</div>
              </div>
            </div>
          </div>
          <div className="home_plate-button">
            <div className="home_plate-button-icon">
              <svg className="icon -cash">
                <use xlinkHref="#svg_cash" />
              </svg>
            </div>
            <div className="home_plate-button-content">
              <div className="home_plate-button-title">
                Get 5% cash back
              </div>
              <div className="home_plate-button-text">
                Pay in store
              </div>
            </div>
            <div className="home_plate-button_spacer" />
            <div>
              <svg className="icon -right">
                <use xlinkHref="#svg_right" />
              </svg>
            </div>
          </div>
          <div className="home_dots">
            <div className="home_dot -active" />
            <div className="home_dot" />
            <div className="home_dot" />
          </div>
        </div>
        <div className="home_section">
          <div className="home_offers">
            <div className="home_grid">
              <h3>
                Featured offers
              </h3>
              <div className="home_see-all">See all</div>
            </div>
            <img className="home_offers-img" src={ imgOffer } alt="" />
            <div className="home_hint">
              Terms & exclusions apply. Check offers for details.
            </div>
          </div>
          <div className="home_recommended">
            <div className="home_grid">
              <h3>
                Recommended for you
              </h3>
              <div className="home_see-all">See all</div>
            </div>
            <img className="home_offers-img" src={ imgOffer } alt="" />
          </div>
        </div>
      </main>
      <footer className="home_footer">
        <div className="home_footer-item -active">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_home" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Home
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_wallet" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Accounts
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon -blue">
            <svg className="icon">
              <use xlinkHref="#svg_arrows" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Send / Request
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_receipts" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Activity
          </div>
        </div>
        <div className="home_footer-item">
          <div className="home_footer-item-icon">
            <svg className="icon">
              <use xlinkHref="#svg_bag" />
            </svg>
          </div>
          <div className="home_footer-item-text">
            Offers
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
