import React, { useContext, useState, useEffect } from 'react';
import { includes, map } from "lodash";
import Modal from "../components/modal";
import TermsApply from "../modals/termsApply";
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import { GlobalContext } from '../contexts/appContexts';

const Benefits = ({ headerStep, numberOfHeaderSteps }) => {
  const { nextStep, skipStep, mergeData, fakeData } = useContext(GlobalContext);
  const [ openModalTerms, setOpenModalTerms ] = useState(false);

  useEffect(() => {
    mergeData({ benefitsShown: true });
  }, []);

  const { benefitsList, plansBenefits } = fakeData;

  const closeModalTerms = () => {
    setOpenModalTerms(false);
  };

  const onNext = () => {
    mergeData({ benefitsSkip: false });
    nextStep();
  };

  const onSkip = () => {
    mergeData({ benefitsSkip: true });
    skipStep();
  };

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const footerButtons = (
    <>
      <button className="button -full-width" onClick={ onNext }>
        I Want All the Benefits
      </button>
      <button className="button -link -full-width" onClick={ onSkip }>
        Skip for now
      </button>
    </>
  );

  const handleOpenModalTerms = () => {
    mergeData({ benefitsOpenTerms: true });
    setOpenModalTerms(true);
  };

  const footerHintAbove = (
    <>
      5% cash back earned as points you redeem for cash and other options on up to
      { " " }
      $1000 spend/month everywhere Mastercard is accepted.
      { " " }
      <button className="button-link -underlined" onClick={ handleOpenModalTerms }>
        Terms apply
      </button>.
    </>
  );

  const footer = <Footer footerButtons={ footerButtons } footerHintAbove={ footerHintAbove } />;

  const getIconName = (listIds, idItem) => {
    return includes(listIds, idItem) ? "checked" : "dots-3";
  };

  return (
    <Layout header={ header } footer={ footer }>

      <div className="benefits">
        <div className="content_title">
          <h1>Don’t miss out on all these benefits</h1>
        </div>

        <div className="content_text">
          It’s easy and free to set up your PayPal Balance account and PayPal Debit Card.
        </div>

        <div className="benefits_table-wrapper">
          <table className="benefits_table">
            <thead>
              <tr>
                <th />
                {
                  map(plansBenefits, (plan, index) => (
                    <th key={ index }>
                      {plan.title}
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                map(benefitsList, (benefit, index) => (
                  <tr key={ benefit.id }>
                    <td className="td-first">
                      {benefit.title}
                    </td>
                    {
                      map(plansBenefits, (plan, index) => {
                        const iconName = getIconName(plan.benefitsList, benefit.id);
                        return (
                          <td key={ index }>
                            <svg className={ `benefit_table-icon -${iconName}` }>
                              <use xlinkHref={ `#svg_${iconName}` } />
                            </svg>
                          </td>
                        );
                      })}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>


      </div>
      {openModalTerms && <Modal handleClose={ closeModalTerms } headerTitle="Terms apply" classNameDialog="-full-height" classNameContent="-bg-gray" >
        <TermsApply />
      </Modal>}
    </Layout>
  );
};

export default Benefits;


