import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html';

const Portal = ({ children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.removeChild(element);
      body.style.overflow = null;
      // todo tanya: check it and maybe remove
      //hack for safari to correct behavior of modal in iframe
      const topCoord = html.scrollTop;
      html.scrollTo({ top: 0 });
      html.scrollTo({ top: topCoord });
    };
  }, [ body, element, html ]);


  return createPortal(children, element);
};

const Modal = ({
  headerTitle, handleClose,
  classNameHeader, classNameContent, className, classNameDialog,
  children, footer, footerInContent, scrollBottomButton, scrollBottomButtonText
}) => {
  const bottomRef = useRef();

  const scrollToBottom = (event) => {
    bottomRef.current.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    });
    setTimeout(() => {
      event.target.blur();
    }, 100);
  };

  const onClose = () => {
    handleClose && handleClose();
  };

  return (
    <Portal>
      <div className={ `modal ${className ? className : ''}` }>
        <div className="modal_backdrop" onClick={ onClose } />
        <div className={ classnames("modal_dialog", classNameDialog) }>
          <div className={ classnames("modal_header", classNameHeader) }>
            { !!headerTitle && <h2 className="modal_title" { ...renderRawHtml(headerTitle) } /> }
            {
              handleClose &&
              <button className="modal_close" onClick={ onClose } type="button">
                <svg className="icon modal_close-icon">
                  <use xlinkHref={ `#svg_close` } />
                </svg>
              </button>
            }
          </div>
          <div
            className={
              classnames(
                "modal_content",
                classNameContent,
                { "-with-footer": !!footer || !!footerInContent }
              )
            }
          >
            {children}
            { !!footerInContent && (
              <div className="modal_footer-in-content" ref={ bottomRef }>{ footerInContent }</div>
            )}
          </div>
          {!!footer && (
            <div className="modal_footer">{ footer }</div>
          )}
          {!!scrollBottomButton && (
            <div className="modal_bottom-button">
              <button className="button" onClick={ scrollToBottom }>
                {scrollBottomButtonText || "Scroll to bottom" }
              </button>
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
