import React, { useState } from 'react';
import classnames from 'classnames';
import InputNumber from '../../../../express/respondent/common/components/inputNumber';

const InlineEdit = ({ prefix = '$', value, setValue, className, children, onEdit }) => {
  const [ editing, setEditing ] = useState(false);
  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onEdit && onEdit();
    setEditing(true);
  };
  const handleChangeValue = (value) => {
    setValue(value);
  };

  const handleOnBlur = () => {
    setEditing(false);
  };

  return (
    <>
      {
        editing ? (
          <span className={ classnames("button-link -md -grid", className) }>
            {prefix}
            <InputNumber
              className="inline-edit-form"
              checkNumberRules
              value={ value }
              onUpdate={ handleChangeValue }
              maxLength={ 6 }
              onBlur={ handleOnBlur }
              autoFocus
            />
            {children}
          </span>
        ) : (
          <button
            className={ classnames("button-link -md") }
            onClick={ handleOnClick }
          >
            {prefix}{value}{children}
          </button>
        )
      }
    </>
  );
};

export default InlineEdit;
