import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import Modal from "../components/modal";
import TermsApply from "../modals/termsApply";
import { GlobalContext } from '../contexts/appContexts';
import InlineEdit from '../components/inlineEdit';
import { cardLast4 } from '../helpers/cardHelper';
import Layout from "../components/layout";
import Footer from "../components/footer";
import Header from "../components/header";
import AddCardForm from "./addCardForm";

const PPEAutoReload = ({ headerStep, numberOfHeaderSteps }) => {
  const { fakeData, nextStep, skipStep, currentData, mergeData } = useContext(GlobalContext);
  const [ cardNumber, setCardNumber ] = useState('');
  const [ cardExpiration, setCardExpiration ] = useState('');
  const [ cardCvv, setCardCvv ] = useState('');
  const [ tempCardNumber, setTempCardNumber ] = useState(cardNumber);
  const [ tempCardExpiration, setTempCardExpiration ] = useState(cardExpiration);
  const [ tempCardCvv, setTempCardCvv ] = useState(cardCvv);
  const [ addMoney, setAddMoney ] = useState(300);
  const [ balanceBelow, setBalanceBelow ] = useState(100);
  const [ oneTimeAmount, setOneTimeAmount ] = useState(0);
  const [ autoReloadForm, setAutoReloadForm ] = useState(true);
  const [ openModalBonus, setOpenModalBonus ] = useState(true);
  // const [ openModalBonus, setOpenModalBonus ] = useState(true);
  const [ openModalTerms, setOpenModalTerms ] = useState(false);
  const [ openModalAddCard, setOpenModalAddCard ] = useState(false);

  const oneTimeNumbers = [ 10, 25, 50, 100 ];

  const closeModalBonus = () => {
    setOpenModalBonus(false);
  };

  const closeModalTerms = () => {
    setOpenModalTerms(false);
  };

  const closeModalAddCard = () => {
    setTempCardNumber('');
    setTempCardExpiration('');
    setTempCardCvv('');
    setOpenModalAddCard(false);
  };

  const handleReloadNext = () => {
    mergeData(
      {
        card: { cardNumber, cardExpiration, cardCvv },
        autoReload: true,
        oneTimeSend: false,
        autoReloadSkipSetupCard: false,
        oneTimeSkipSetupCard: false,
        oneTimeAmount: null,
        addMoney,
        balanceBelow
      }
    );
    nextStep();
  };

  const handleOneTimeNext = () => {
    mergeData({
      card: { cardNumber, cardExpiration, cardCvv },
      autoReload: false,
      oneTimeSend: true,
      addMoney: null,
      balanceBelow: null,
      autoReloadSkipSetupCard: false,
      oneTimeSkipSetupCard: false,
      oneTimeAmount
    });
    nextStep();
  };

  const handleSkip = () => {
    mergeData({
      card: null,
      autoReload: false,
      oneTimeSend: false,
      addMoney: null,
      balanceBelow: null,
      oneTimeAmount: null,
      autoReloadSkipSetupCard: !!autoReloadForm,
      oneTimeSkipSetupCard: !autoReloadForm
    });
    skipStep();
  };

  const handleOpenTerms = () => {
    mergeData({ autoReloadTermsApply: true });
    setOpenModalTerms(true);
  };

  const footerModalBonus = (
    <>
      <div className="modal_footer-hint -above -black">
        <button className="button-link -underlined" onClick={ handleOpenTerms }>
          Terms apply
        </button>.
        { ' ' }
        Cash back earned as points you redeem for cash and other options.
        { ' ' }
        Complete steps by { fakeData.completeStepsByData } at { fakeData.completeStepsByTime }.
      </div>
      <div className="modal_footer-button">
        <button className="button -full-width" onClick={ () => { closeModalBonus(); } }>
          Next
        </button>
      </div>
    </>
  );

  const handleAddCard = () => {
    setCardNumber(tempCardNumber);
    setCardExpiration(tempCardExpiration);
    setCardCvv(tempCardCvv);
    closeModalAddCard();
  };
  const disabledAddCard = (!tempCardNumber || !tempCardExpiration || !tempCardCvv);
  const footerModalAddCard = (
    <>
      <div className="modal_footer-button">
        <button
          disabled={ disabledAddCard }
          className="button -full-width"
          onClick={ handleAddCard }
        >
          Add Card
        </button>
      </div>
    </>
  );

  const header = <Header currentStep={ headerStep } numberOfSteps={ numberOfHeaderSteps } />;

  const disabledAddMoney = (
    !cardNumber || !cardExpiration || !cardCvv || !parseInt(addMoney) || !parseInt(balanceBelow)
  );
  const reloadFooterButtons = (
    <>
      <button
        disabled={ disabledAddMoney }
        className="button -full-width"
        onClick={ handleReloadNext }
      >
        Add Money and Authorize Reload
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const disabledOneTimeAdd = (
    !cardNumber || !cardExpiration || !cardCvv || !parseInt(oneTimeAmount)
  );
  const oneTimeFooterButtons = (
    <>
      <button
        disabled={ disabledOneTimeAdd }
        className="button -full-width"
        onClick={ handleOneTimeNext }
      >
        Add ${ oneTimeAmount }.00 Now
      </button>
      <button className="button -link -full-width" onClick={ handleSkip }>
        Skip
      </button>
    </>
  );

  const footer = (
    <Footer footerButtons={ autoReloadForm ? reloadFooterButtons : oneTimeFooterButtons } />
  );

  const toggleForms = () => {
    if (autoReloadForm) {
      mergeData({ oneTimeSelected: true, oneTimeFormClick: true });
    } else {
      mergeData({ oneTimeSelected: false, autoReloadFormClick: true });
    }
    setAutoReloadForm(!autoReloadForm);
  };

  const handleOneTimePredefinedClick = (number) => {
    mergeData({ oneTimePredefinedValueClick: true });
    setOneTimeAmount(number);
  };

  const handleAutoReloadAddCard = () => {
    mergeData({ autoReloadAddCardClick: true });
    setOpenModalAddCard(true);
  };

  const handleOneTimeAddCard = () => {
    mergeData({ oneTimeAddCardClick: true });
    setOpenModalAddCard(true);
  };

  const handleOnScan = () => {
    if (autoReloadForm) {
      mergeData({ autoReloadCardScanClick: true });
    } else {
      mergeData({ oneTimeCardScanClick: true });
    }
  };

  const handleEditAddMoney = () => {
    mergeData({ autoReloadAutomaticallyAddEdit: true });
  };
  const handleEditBalance = () => {
    mergeData({ autoReloadBalanceEdit: true });
  };
  const handleOneTimeAmountEdit = () => {
    mergeData({ oneTimeAmountEdit: true });
  };

  return (
    <Layout header={ header } footer={ footer }>
      {
        autoReloadForm ? (
          <div className="ppe-autoreload">
            <div className="content_title">
              <h1>Set up auto reload so you’re ready to shop</h1>
            </div>
            <div className="ppe-autoreload_form">
              <div className="ppe-autoreload_form-plates">
                <div className="form-plate">
                  <div className="form-plate_grid">
                    <div className="form-plate_grid-item">
                      <div className="form-plate_name">From</div>
                    </div>
                    <div className="form-plate_grid-item">
                      <button className="button-link" onClick={ handleAutoReloadAddCard }>
                        {
                          cardNumber ? (
                            <span className="selected-card">
                              <span className="card-title">Chase Visa Debit</span>
                              <span className="card-value">Visa { cardLast4(cardNumber) }</span>
                            </span>
                          ) : (
                            <>Add payment method</>
                          )
                        }
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-plate">
                  <div className="form-plate_grid">
                    <div className="form-plate_grid-item">
                      <div className="form-plate_name">Automatically add</div>
                    </div>
                    <div className="form-plate_grid-item">
                      <InlineEdit
                        value={ addMoney }
                        setValue={ setAddMoney }
                        onEdit={ handleEditAddMoney }
                      />
                    </div>
                  </div>
                  <div className="form-plate_grid">
                    <div className="form-plate_grid-item">
                      <div className="form-plate_name">When balance is below</div>
                    </div>
                    <div className="form-plate_grid-item">
                      <InlineEdit
                        value={ balanceBelow }
                        setValue={ setBalanceBelow }
                        onEdit={ handleEditBalance }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ppe-autoreload_form-button-center">
                <button className="button-link" onClick={ toggleForms }>
                  Make a one-time transfer instead
                </button>
              </div>
              <div className="ppe-autoreload_form-hint">
                <svg className="icon -info">
                  <use xlinkHref="#svg_info" />
                </svg>
                Visa or Mastercard debit transfers to PayPal balance typically take seconds
                {' '}
                if there are sufficient funds. You can change or cancel this authorization
                {' '}
                any time from the Accounts tab, which may take up to 24 hours to process.
                {' '}
                Auto reloads are limited to 2 per day. You can shop with your PayPal Debit Card up
                {' '}
                to your available PayPal balance.
              </div>
            </div>
          </div>
        ) : (
          <div className="ppe-autoreload">
            <div className="content_title">
              <h1>Add money one time</h1>
            </div>
            <div className="content_text">
              Add money to your PayPal balance now to start
              { ' ' }
              shopping with your PayPal Debit Card.
            </div>
            <div className="ppe-autoreload_form">
              <div className="ppe-autoreload_form-plates">
                <div className="one-time-value">
                  <InlineEdit
                    value={ oneTimeAmount }
                    setValue={ setOneTimeAmount }
                    onEdit={ handleOneTimeAmountEdit }
                  >
                    <svg className="icon -one-time-edit">
                      <use xlinkHref="#svg_one-time-edit" />
                    </svg>
                  </InlineEdit>
                </div>
                <div className="one-time-predefined">
                  {
                    map(oneTimeNumbers, (oneTimeNumber) => (
                      <div
                        key={ oneTimeNumber }
                        className="form-plate"
                        onClick={ () => handleOneTimePredefinedClick(oneTimeNumber) }
                      >
                        ${oneTimeNumber}
                      </div>
                    ))
                  }

                </div>
                <div className="form-plate">
                  <div className="form-plate_grid">
                    <div className="form-plate_grid-item">
                      <div className="form-plate_name">From</div>
                    </div>
                    <div className="form-plate_grid-item">
                      <button className="button-link" onClick={ handleOneTimeAddCard }>
                        {
                          cardNumber ? (
                            <span className="selected-card">
                              <span className="card-title">Chase Visa Debit</span>
                              <span className="card-value">Visa ••••{cardNumber.slice(-4)}</span>
                            </span>
                          ) : (
                            <>Add payment method</>
                          )
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ppe-autoreload_form-button-center">
                <button className="button-link" onClick={ toggleForms }>Set up auto reload</button>
              </div>
              <div className="ppe-autoreload_form-hint">
                <svg className="icon -info">
                  <use xlinkHref="#svg_info" />
                </svg>
                Transfers typically occur in seconds for Mastercard or Visa debit cards.
                { ' ' }
                They are subject to review and may be delayed or declined if we identify an issue.
              </div>
            </div>
          </div>
        )
      }

      {openModalBonus && <Modal handleClose={ closeModalBonus } footer={ footerModalBonus }>
        <div className="bonus">
          <div className="title modal_content-title">
            <h1 className="title_h1">Get a {fakeData.cashBackBonus} cash back bonus</h1>
          </div>
          <ol className="bonus_list">
            <li>
              Set up auto reload and add money so your PayPal Debit Card is ready to shop!
            </li>
            <li>
              Add to Apple Wallet to pay in stores.
            </li>
          </ol>
        </div>
      </Modal>}

      {openModalAddCard && <Modal handleClose={ closeModalAddCard } footerInContent={ footerModalAddCard }>
        <div className="add-card">
          <div className="title modal_content-title">
            <h1 className="title_h1">Link your bank so you can add money to your PayPal balance</h1>
          </div>
          <AddCardForm
            fakeData={ fakeData }
            billingAddress={ currentData.billingAddress }
            cardNumber={ tempCardNumber }
            setCardNumber={ setTempCardNumber }
            cardExpiration={ tempCardExpiration }
            setCardExpiration={ setTempCardExpiration }
            cardCvv={ tempCardCvv }
            setCardCvv={ setTempCardCvv }
            onScan={ handleOnScan }
          />
        </div>
      </Modal>}

      {openModalTerms && <Modal handleClose={ closeModalTerms } headerTitle="Terms apply" classNameDialog="-full-height" classNameContent="-bg-gray" >
        <TermsApply />
      </Modal>}
    </Layout>
  );
};

export default PPEAutoReload;


