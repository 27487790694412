import React, { useContext, useState } from 'react';
import Confetti from 'react-confetti';
import Modal from "../components/modal";
import { GlobalContext } from '../contexts/appContexts';
import HomePage from "./homePage";

const Celebration = ({ title, text }) => {
  const { nextStep, currentData } = useContext(GlobalContext);
  const [ openModal ] = useState(true);

  const closeModal = () => {
    nextStep();
  };

  const balance = () => {
    if (currentData.autoReload) {
      return currentData.addMoney;
    } else if (currentData.oneTimeSend) {
      return currentData.oneTimeAmount;
    }
    return 300;
  };

  return (
    <div>
      {
        openModal &&
        <div className="confetti">
          <Confetti width={ 400 } height={ 2000 } numberOfPieces={ 400 } />
        </div>
      }
      <HomePage balance={ balance() } />
      {
        openModal &&
        <Modal handleClose={ closeModal }>
          <div className="celebration">
            <div className="title modal_content-title">
              <h1 className="title_h1">{ title }</h1>
            </div>
            <div className="modal_content-text">{ text }</div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default Celebration;
